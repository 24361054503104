<template>
  <div class="container">
    <div class="personal__title title">Мой пользовательский счет</div>
    <div class="personal__bonuses">
      {{ account.budget + " " + account.currency }}
    </div>
    <div class="personal-text">
      <div class="personal-text__subtitle title title--second">
        Пополнение счета
      </div>
      <div class="personal-text__paragraph text">
        Уважаемые клиенты, на новом сайте мы разделили реальные деньги и
        бонусные рубли на два отдельных счета:
      </div>
      <ul class="personal-text__list list">
        <li class="personal-text__list-item list__item">
          <strong>Личный счет&#32;</strong>— на этом счете находятся Ваши
          реальные деньги, которые были Вами зачислены с платежных систем, либо
          с банковских карт.
        </li>
        <li class="personal-text__list-item list__item">
          <strong>Бонусный счет&#32;</strong>— предназначен исключительно для
          хранения накопленных бонусов. Узнать подробности&#32;
          <a href="https://palladi.ru/info/bonusnaya-programma-2-0/" class="personal-text__list-link">
            бонусной программы.
          </a>
        </li>
      </ul>
    </div>
    <div class="personal-text">
      <div class="personal-text__paragraph text">
        Пополнение личного счета с помощью платежных систем или банковских карт
        простановлено. Вместо возможности пополнить свой лицевой счет, для
        Вашего удобства, мы добавим возможность оплаты заказов ОНЛАЙН.
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("PERSONAL_SET_ACCOUNT").then(() => {
      next();
    });
  },

  computed: {
    account() {
      return this.$store.state.personal.account;
    },
  },
};
</script>
